<template>
  <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M15.9013 0.176775C12.6292 3.17462 9.87942 7.04075 7.29562 10.7271C6.27236 8.64326 5.23098 6.31262 3.72175 4.73143C3.19956 4.18609 2.35137 4.75276 2.50833 5.44434C2.81018 6.76656 3.58592 7.96996 4.21678 9.15814C4.91706 10.4773 5.62037 11.7934 6.32367 13.1126C6.59533 13.6214 7.47069 13.8103 7.85705 13.3106C10.9389 9.34398 14.4252 5.34685 16.9094 0.962797C17.2807 0.310826 16.4355 -0.313726 15.9013 0.176775Z" fill="#231F20"></path>
      <path d="M15.7955 7.95739C15.8197 7.32369 14.7813 7.15613 14.6787 7.80506C14.2651 10.3886 14.244 13.0056 14.5096 15.6043C12.1854 15.2174 9.62878 15.4947 7.30456 15.4703C5.95833 15.4581 4.61511 15.4429 3.27189 15.4459C2.58972 15.4459 1.94981 15.3423 1.38535 15.516C1.39139 13.1183 1.38837 10.7237 1.38535 8.32602C1.37932 6.40057 1.61777 4.17047 1.28574 2.21151C3.23869 2.27853 5.19466 2.309 7.15062 2.26025C8.77456 2.22065 10.9177 2.47351 12.3635 1.63875C12.5688 1.51993 12.4933 1.25488 12.3031 1.17566C10.7305 0.532833 8.53006 1.01115 6.84877 1.03857C4.85055 1.06903 2.85534 1.04771 0.857121 0.977636C-0.172176 0.941077 0.120615 3.66778 0.114578 4.34108C0.0994857 5.66939 0.138726 6.99771 0.12967 8.32602C0.117596 9.94986 0.105523 11.5706 0.0994857 13.1945C0.0934488 14.1938 -0.437801 16.5336 1.01106 16.6981C1.77172 16.7834 2.50822 16.8778 3.27189 16.8809C4.61511 16.8839 5.96135 16.8687 7.30456 16.8565C9.57747 16.8352 11.8353 16.9997 14.1082 16.9997C14.4915 16.9997 14.902 16.9936 15.219 16.7742C15.7895 16.3782 15.877 15.6957 15.8378 15.0651C15.7865 14.2517 15.7563 13.4352 15.7412 12.6217C15.711 11.0649 15.7382 9.51115 15.7955 7.95739Z" fill="#231F20"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="17" height="17" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ConfirmIcon'
}
</script>
