<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path id="Icon_awesome-pen" data-name="Icon awesome-pen" d="M6.814,2.186l3,3L3.3,11.7.624,12A.563.563,0,0,1,0,11.375L.3,8.7,6.814,2.186Zm4.856-.447L10.261.33a1.126,1.126,0,0,0-1.592,0L7.344,1.655l3,3L11.67,3.33a1.126,1.126,0,0,0,0-1.592Z" transform="translate(0.001 -0.001)" fill="#8b97a5"></path>
  </svg>
</template>

<script>
export default {
  name: 'PencilIcon'
}
</script>
