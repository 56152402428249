<template>
  <div class="crm__notes comment_block">
    <MailMessageModal v-if="showModal" :showModal="showModal" :uidMessage="uidEmailMessage" @closeModal="closeModal"/>
    <div v-if="pinnedNotes.length" class="crm__notes__pinned crm__note">
      <div class="crm__note__header">
        <span class="pinned__header__text">Закреплённый комментарий</span>
        <div class="crm__note__header__actions">
          <span class="pinned__pagination" @click="nextPinnedNote" v-tooltip="nextPinnedNoteTitle">
            <span>{{ pinnedNoteI + 1 }}/{{ pinnedNotes.length }}</span>
          </span>
          <a v-if="$store.state.me.id === pinnedNotes[pinnedNoteI].responsible_user_id" @click="unpinNote($event, pinnedNotes[pinnedNoteI]._id)" class="crm__note__action" href="#" v-tooltip="unpinTitle">
            <TrashIcon/>
          </a>
        </div>
      </div>
      <div class="crm__note__body pinned__body" @click="scrollToElement(pinnedNotes[pinnedNoteI]._id)">{{ pinnedNotes[pinnedNoteI].text }}</div>
    </div>
    <div class="comment_block__top" ref="list">
      <div v-if="isLoading && $store.state.me.settings.lazy_load" class="messages apollo">
        <div class="messages__status">Загрузка...</div>
      </div>
      <ul v-if="noteMode !== 'Email'" class="crm__notes__list">
        <li v-if="pinnedNotes.length" class="crm__note hidden">
          <div class="crm__note__header">
            <span class="pinned__header__text">-</span>
            <div class="crm__note__header__actions">-</div>
          </div>
          <div class="crm__note__body">-</div>
        </li>
        <li :key="index" class="crm__note" :id="note._id" :class="{ 'crm__note--red': isOutdated(note)}" v-for="(note, index) in currentNotes">
          <div class="crm__note__header">
            <div class="crm__note__header__info">
              <i class="crm__note__icon" v-if="isOutdated(note)">
                <RedBellIcon/>
              </i>
              <i class="crm__note__icon" v-else-if="note.note_type === 'Service'">
                <NewSettingsIcon/>
              </i>
              <i class="crm__note__icon crm__note__icon--green" v-else-if="note.task_type">
                <ConfirmIcon/>
              </i>
              <i class="crm__note__icon" v-else>
                <CtFileIcon/>
              </i>
              <span class="crm__note__date">{{ formatDate(note.createdAt) }}</span>
              <span class="crm__note__banned-employee" v-if="note.created_by && note.created_by.banned"><span class="tag tag--red">Уволенный сотрудник</span></span>
              <span class="crm__note__manager" v-if="!note.task_type && note.responsible_user && note.responsible_user.name === 'Фархутдинов Ильдар Рашитович'">Робот Роберт</span>
              <span class="crm__note__manager" v-else>{{ note.created_by && note.created_by.name ? note.created_by.name : '-' }}</span>
              <!-- <span class="crm__note__manager" v-else>2{{ note.responsible_user && note.responsible_user.name ? note.responsible_user.name : '-' }}</span> -->
              <span class="crm__div" v-if="note.element && note.element.name">для</span>
              <router-link :to="{ name: getRouteOfElementType(note.element_type), params: { id: note.element.id } }" class="crm__note__responsible" v-if="note.element && note.element.name">
                {{ note.element && note.element.name ? note.element.name : '-' }}
              </router-link>
            </div>
            <div class="crm__note__header__actions" v-if="note.responsible_user && note.is_editable && !note.task_type && $store.state.me._id && $store.state.me._id === note.responsible_user._id">
              <a @click="pinNote($event, note._id)" class="crm__note__action" href="#" v-tooltip="pinTitle">
                <PinIcon/>
              </a>
              <a @click="editNote($event, note._id)" class="crm__note__action" href="#" v-tooltip="editTitle">
                <PencilIcon/>
              </a>
              <a @click="deleteNote($event, note._id)" class="crm__note__action" href="#" v-tooltip="deleteTitle">
                <TrashIcon/>
              </a>
            </div>
            <div class="crm__note__header__actions" v-if="note.task_type && note.is_editable">
              <a @click="editTask($event, note._id)" class="crm__note__action" href="#" v-if="$store.state.me._id && $store.state.me._id === note.created_by._id" v-tooltip="editTitle">
                <PencilIcon/>
              </a>
              <a @click="deleteTask($event, note._id)" class="crm__note__action" href="#" v-if="$store.state.me._id && $store.state.me._id === note.created_by._id" v-tooltip="deleteTitle">
                <TrashIcon/>
              </a>
            </div>
          </div>
          <div class="crm__note__body" v-if="!note.task_type">
            <span style="white-space: pre-wrap" class="crm__note__text">{{ note.text }}</span>
          </div>
          <div class="crm__note__body" v-else>
            <p>
              <span class="crm__note__text">Задача для</span>
              <strong>{{ note.responsible_user.name }}</strong> до
              <span>{{ formatDate(note.complete_till_at) }}</span>
              .
            </p>
            <p>
              <span v-if="note.text" class="crm__note__text">{{ getCurrentTaskType(note.task_type) + ': ' + note.text }}</span>
            </p>
            <div class="crm__note__close-outdated" v-if="!note.is_completed && $store.state.me._id === note.responsible_user._id">
              <input v-model="outdateText">
              <button @click="completeTask($event, note)" class="btn">Завершить</button>
            </div>
          </div>
          <div class="crm__note__footer" v-if="note.task_type">
            <span>Статус: {{ note.is_completed ? 'Завершена' : 'Открыта' }}</span>
          </div>
        </li>
      </ul>
      <ul v-else-if="noteMode === 'Email' && elementEmail && elementEmail !== ''" class="crm__email__list">
        <ApolloQuery
          :query="require('@/graphql/queries/MailMessages.gql')"
          :variables="{ filter: { client: elementEmail } }"
          @result="doneLoadingMessages"
        >
          <template slot-scope="{ result: { error, data } }">
            <div v-if="isEmailLoading" class="messages__status loading">Загрузка</div>
            <div class="ct__row" v-if="error">
              <div class="ct__col">
                <div class="ct__input">
                  <label for="message">Ошибка:</label>
                  <input type="text" :value="error.message" id="message" disabled />
                </div>
              </div>
            </div>
            <!-- Здесь ещё флаг шлёпнуть тип входящий или исходящий -->
            <li v-else-if="data && data.MailMessages" v-for="(item) in data.MailMessages" :key="item.uid" class="crm__email__item" :class="{'crm__email__item--send': item.type === 'send'}">
              <div class="crm__email" :class="`${item.type}-mail`" @click="openMessage(item.uid)">
                <div class="crm__email__header">
                  <!-- <div class="crm__email__icon">[X]</div> -->
                  <div class="crm__email__datetime">{{ new Date(item.date).toLocaleString('ru-RU') }}</div>
                  <div class="crm__email__user">{{ item.type === 'receive' ? item.company_address : $store.state.me.email }}</div>
                </div>
                <div class="crm__email__body">
                  <div class="crm__email__text">{{ item.text }}</div>
                </div>
                <div class="crm__email__footer">
                  <div v-if="item.type === 'receive'" class="crm__email__status">{{ item.viewed ? 'Просмотрено' : 'Не просмотрено' }}</div>
                  <div v-else class="crm__email__status">Отправлено</div>
                </div>
              </div>
            </li>
          </template>
        </ApolloQuery>
      </ul>
    </div>
    <div v-if="!scroll && commentHidden" class="comment_block__bottom comment_action comment_fixed comment_fixed--hidden">
      <input type="text" placeholder="Написать комментарий или создать задачу" @click="commentHidden = false" v-model="noteEdit">
    </div>
    <div v-else
      class="comment_block__bottom comment_action"
      :class="{
        'comment_fixed': !scroll && !commentHidden
      }"
    >
      <div class="crm__notes__overdue" v-if="activeTasks.filter(note => !note.is_completed && note.task_type).length">
        <i class="crm__notes__overdue__icon" style="cursor: pointer;" :class="{'crm__notes__overdue__icon--disactive': !showActiveTasks}" @click="showActiveTasks = !showActiveTasks">
          <Ring/>
        </i>
        <ul class="crm__notes__overdue__list" v-if="showActiveTasks">
          <li v-for="(n, i) in activeTasks.filter(note => !note.is_completed && note.task_type)" :key="i" @click="scrollToElement(n._id)" style="cursor: pointer;">
            <span>{{ formatDate(n.complete_till_at) }}</span>
            <span>
              <span class="crm__notes__overdue__div">для</span>
              <span>{{ n.responsible_user.name }} - {{ getCurrentTaskType(n.task_type) }}</span>
            </span>
          </li>
        </ul>
      </div>
      <div class="crm__notes__form" v-if="elementType && elementId">
        <div class="crm__notes__form__header">
          <div @mouseleave="showActions = false" class="crm__notes__type">
            <div @mouseover="showActions = true" class="crm__notes__type__selected">
              <span>{{ noteMode }}</span>
            </div>
            <ul class="crm__notes__type__list" v-if="showActions">
              <li :key="i" @click="noteMode = item; showActions = false" v-for="(item, i) in noteModeSelect">{{ item }}</li>
            </ul>
          </div>
          <div @mouseleave="showActionsTask = false" class="crm__notes__type" v-if="noteMode === 'Задача'">
            <div @mouseover="showActionsTask = true" class="crm__notes__type__selected">
              <span>{{ taskType.name }}</span>
            </div>
            <ul class="crm__notes__type__list" v-if="showActionsTask">
              <li :key="i" @click="taskType = item; showActionsTask = false" v-for="(item, i) in taskTypeSelect">{{ item.name }}</li>
            </ul>
          </div>
          <span class="crm__notes__div" v-if="noteMode === 'Задача'">для</span>
          <v-select appendToBody :clearable="false"
                    :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))"
                    :value="{ label: editTaskManager.name, value: editTaskManager._id}"
                    :calculate-position="calculatePosition"
                    @input="onManagerChange"
                    v-if="noteMode === 'Задача' && (!editMode || !editTaskManager._id)">
            <template slot="no-options">Нет результатов</template>
            <template #open-indicator="{ attributes }">
              <div v-bind="attributes"></div>
            </template>
          </v-select>
          <div v-else-if="noteMode === 'Задача' && (editMode || editTaskManager._id)" class="comment_action__task_choose-editable-user">
            <input v-model="editTaskManager.name" type="text" disabled>
            <Close @click="clearEditTaskManager"/>
          </div>
          <span class="crm__notes__div" v-if="noteMode === 'Задача'">до</span>
          <date-picker
            :formatter="momentFormat"
            :shortcuts="shortcuts"
            :show-time-header="true"
            type="datetime"
            v-if="noteMode === 'Задача'"
            v-model="period"
          ></date-picker>
          <div v-if="$store.state.Lead && elementType === 'Lead' && ['first', 'meeting', 'documents', 'created'].includes($store.state.Lead.status)" class="crm__notes__status__list">
            <span v-if="$store.state.Lead.status === 'first'"
              class="crm__notes__status tag--purple"
              @click="updateStatus('meeting')"
            >Переговоры</span>
            <span v-else-if="$store.state.Lead.status === 'meeting'"
              class="crm__notes__status tag--yellow"
              @click="updateStatus('documents')"
            >Сбор документов</span>
            <span v-if="!['closed', 'spam'].includes($store.state.Lead.status)"
              class="crm__notes__status tag--red"
              @click="updateStatus('closed')"
            >Закрыто</span>
            <span v-if="$store.state.Lead.status === 'first'"
              class="crm__notes__status"
              @click="updateStatus('spam')"
            >Спам</span>
          </div>
        </div>
        <div class="crm__notes__form__body" @mouseleave="hiddenCommentInput()">
          <div id="dz-previews" class="dz-previews"></div>
          <input type="text" v-model="subjectEdit"  placeholder="Тема сообщения..." style="border-color: lightgray; border-bottom-right-radius: 0; border-bottom-left-radius: 0" v-if="noteMode === 'Email'">
          <textarea @keydown="addNoteIfPressCtrlAndEnter" rows="5" v-model="noteEdit"></textarea>
          <!-- <button @click="addNoteIfClickOnButton" class="crm__notes__send" type="button">
            <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
              <rect fill="#FF8000" height="32" rx="16" width="32"></rect>
              <path d="M15.9992 2.16016C8.35918 2.16016 2.15918 8.36016 2.15918 16.0002C2.15918 23.6402 8.35918 29.8402 15.9992 29.8402C23.6392 29.8402 29.8392 23.6402 29.8392 16.0002C29.7992 8.36016 23.6392 2.20016 15.9992 2.16016ZM15.9992 28.6402C9.03918 28.6402 3.35918 22.9602 3.35918 16.0002C3.35918 9.04016 9.03918 3.36016 15.9992 3.36016C22.9592 3.36016 28.6392 9.04016 28.6392 16.0002C28.5992 22.9602 22.9592 28.6002 15.9992 28.6402Z" fill="white"></path>
              <path d="M8.55908 6.6001L12.8791 16.0001L8.55908 25.4001L25.2391 16.0001L8.55908 6.6001ZM11.1991 9.4801L21.7191 15.4001H13.9191L11.1991 9.4801ZM13.9191 16.6001H21.7191L11.1991 22.5201L13.9191 16.6001Z" fill="white"></path>
            </svg>
          </button> -->
          <a v-if="editMode" class="crm__notes__cancel" href="#" @click="cancelEditMode">Отменить изменения</a>
          <div class="dropzone-container crm__notes__drag">
            <vue-dropzone
              v-if="noteMode === 'Email'"
              id='dropzone'
              ref='dropzone'
              @vdropzone-files-added="filesPicked"
              @vdropzone-upload-progress="fileProgress"
              @vdropzone-error="fileError"
              @vdropzone-removed-file="fileRemove"
              @vdropzone-canceled="fileCancel"
              @vdropzone-queue-complete="queueComplete"
              :includeStyling="false"
              useCustomSlot
              :options="dropzoneOptions">
              <div class="dropzone-icon">
                <i>
                  <PaperClip/>
                </i>
              </div>
            </vue-dropzone>
          </div>
          <button @click="addNoteIfClickOnButton" class="crm__notes__send" type="button">
            <NoteSendIcon/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import CtFileIcon from '@/components/svg/CtFileIcon'
import ADD_NOTE from '@/graphql/mutations/AddNote.gql'
import UPDATE_NOTE from '@/graphql/mutations/UpdateNote.gql'
import PIN_NOTE from '@/graphql/mutations/PinNote.gql'
import UNPIN_NOTE from '@/graphql/mutations/UnpinNote.gql'
import DELETE_NOTE from '@/graphql/mutations/DeleteNote.gql'
import ADD_TASK from '@/graphql/mutations/AddTask.gql'
import UPDATE_TASK from '@/graphql/mutations/UpdateTask.gql'
import DELETE_TASK from '@/graphql/mutations/DeleteTask.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ConfirmIcon from '@/components/svg/ConfirmIcon'
import PencilIcon from '@/components/svg/pencil'
import TrashIcon from '@/components/svg/trash_comment'
import Close from '@/components/svg/close'
import NoteSendIcon from '@/components/svg/NoteSendIcon'
import { createPopper } from '@popperjs/core'
import RedBellIcon from '../svg/RedBellIcon'
import Ring from '@/components/svg/ring'
import { extendMoment } from 'moment-range'
import MailMessageModal from '@/components/ui/MailMessageModal'
import vue2Dropzone from 'vue2-dropzone'
import axios from 'axios'
import PaperClip from '@/components/svg/PaperClip'
import PinIcon from '@/components/svg/PinIcon'
import NewSettingsIcon from '@/components/svg/new/Settings'

const BASE_URL = process.env.VUE_APP_HTTP

const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <div class="dz-size"><span data-dz-size></span></div>
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <span class="dz-remove" data-dz-remove><a>Удалить</a></span>
</div>
`

const _moment = extendMoment(moment)
const Today = _moment()
const Tomorrow = _moment().add(1, 'days')
const Week = _moment().endOf('week')
const NextWeek = _moment().endOf('week').add(1, 'week')

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'LsgNotes',
  components: {
    NewSettingsIcon,
    RedBellIcon,
    NoteSendIcon,
    Close,
    TrashIcon,
    PencilIcon,
    ConfirmIcon,
    CtFileIcon,
    Ring,
    MailMessageModal,
    DatePicker,
    vueDropzone: vue2Dropzone,
    PaperClip,
    PinIcon
  },
  props: {
    scroll: {
      type: Boolean,
      default: true
    },
    onLoading: {
      type: Boolean,
      default: true
    },
    activeTasks: {
      type: Array,
      default: () => {
        return []
      }
    },
    position: {
      type: Number
    },
    isRightPage: {
      type: Boolean,
      default: false
    },
    elementType: {
      type: String,
      default: ''
    },
    elementId: {
      type: String,
      default: ''
    },
    elementEmail: String,
    notes: {
      type: Array,
      default: () => {
        return []
      }
    },
    tasks: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      dropzoneOptions: {
        previewTemplate: getTemplate(),
        url: 'https://httpbin.org/post',
        parallelUploads: 2,
        previewsContainer: '#dz-previews'
      },
      commentHidden: true,
      subjectEdit: '',
      showActiveTasks: true,
      files: [],
      showModal: false,
      uidEmailMessage: null,
      isLoading: true,
      isEmailLoading: true,
      position1: 0,
      placement: 'top',
      noteEdit: '',
      editMode: false,
      outdateText: '',
      editNoteId: null,
      currentNotes: [],
      pinTitle: 'Закрепить',
      unpinTitle: 'Открепить',
      nextPinnedNoteTitle: 'Следующий',
      editTitle: 'Изменить',
      deleteTitle: 'Удалить',
      pinnedNoteI: 0,
      showActions: false,
      showActionsTask: false,
      noteMode: 'Комментарий',
      lastNoteMode: 'Комментарий',
      noteModeSelect: ['Комментарий', 'Задача', 'Email'],
      currentTasks: [],
      period: new Date(Date.now()),
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY HH:mm').toDate() : null
        }
      },
      shortcuts: [
        {
          text: 'Через 15 минут',
          onClick () {
            return moment().add(15, 'minutes').toDate()
          }
        },
        {
          text: 'Через 30 минут',
          onClick () {
            return moment().add(30, 'minutes').toDate()
          }
        },
        {
          text: 'Через час',
          onClick () {
            return moment().add(1, 'h').toDate()
          }
        },
        {
          text: 'Сегодня',
          onClick () {
            return moment().endOf('d').toDate()
          }
        },
        {
          text: 'Завтра',
          onClick () {
            return moment().add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'До конца недели',
          onClick () {
            return moment().endOf('w').endOf('d').toDate()
          }
        },
        {
          text: 'Через неделю',
          onClick () {
            return moment().endOf('w').add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'Через месяц',
          onClick () {
            return moment().endOf('M').add(1, 'd').endOf('d').toDate()
          }
        },
        {
          text: 'Через год',
          onClick () {
            return moment().endOf('y').add(1, 'd').endOf('d').toDate()
          }
        }
      ],
      editTaskManager: {
        _id: this.$store.state.me._id,
        name: this.$store.state.me.name
      },
      taskTypeSelect: [
        {
          value: 'Call',
          name: 'Звонок'
        }, {
          value: 'Meeting',
          name: 'Переговоры'
        }, {
          value: 'WriteEmail',
          name: 'Написать письмо'
        }, {
          value: 'Get_docs',
          name: 'Получить документы'
        }, {
          value: 'Action',
          name: 'Прочее'
        }],
      taskType: {
        value: 'Call',
        name: 'Звонок'
      },
      windowWidth: 0
    }
  },
  watch: {
    noteMode () {
      this.editTaskManager = {
        _id: this.$store.state.me._id,
        name: this.$store.state.me.name
      }
    },
    'currentNotes.length' () {
      this.isLoading = this.onLoading
      if (this.position === 1000000) {
        this.scrollDown()
      } else {
        this.$nextTick(() => {
          this.$refs.list.$el.scrollTop = this.$refs.list.ps.contentHeight / 2
        })
      }
    }
  },
  computed: {
    pinnedNotes: function () {
      if (this.notes && this.notes.length) {
        return this.notes.filter(note => note.isPinned)
      }
      return []
    }
  },
  mounted () {
    if (this.notes.length) this.currentNotes = this.notes
    if (this.tasks.length) {
      let localNotes = this.currentNotes
      this.currentNotes = localNotes.concat(this.tasks.filter(i => localNotes.indexOf(i) === -1))
    }

    this.currentNotes.sort(function (a, b) {
      let dateA = new Date(a.createdAt)
      let dateB = new Date(b.createdAt)
      return dateA - dateB
    })

    this.$nextTick(() => {
      if (this.$refs.list && this.$refs.list.scrollHeight) this.$refs.list.scrollBy({ top: this.$refs.list.scrollHeight })
    })
    // this.$refs.list.$el.scrollTop = this.$refs.list.$el.scrollHeight

    // if (this.position === 1000000) {
    //   this.scrollDown()
    // } else if (this.$refs.list.ps.contentHeight / 2 < this.$refs.list.$el.scrollTop) {
    //   this.$nextTick(() => {
    //     this.$refs.list.$el.scrollTop = 0
    //   })
    // } else {
    //   this.$nextTick(() => {
    //     this.$refs.list.$el.scrollTop = this.$refs.list.ps.contentHeight / 2
    //   })
    // }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    nextPinnedNote () {
      if (this.pinnedNoteI + 1 === this.pinnedNotes.length) this.pinnedNoteI = 0
      else this.pinnedNoteI++
    },
    hiddenCommentInput () {
      if (!this.scroll) this.commentHidden = true
    },
    scrollToElement (id) {
      const element = document.getElementById(id)
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    queueComplete () {
      if (this.files.length > 0) {
        this.files.forEach(elem => {
          if (elem.status !== 'success') {
            this.$notify({
              group: 'lsg-notify',
              text: 'Ошибка при загрузке файлов'
            })
          } else {
            this.$notify({
              group: 'lsg-notify',
              text: 'Файлы загружены'
            })
          }
        })
      }
    },
    fileCancel (file) {
      this.$refs.dropzone.removeAllFiles()
      this.$notify({
        group: 'lsg-notify',
        text: `Отмена загрузки файла ${file.name}`
      })
    },
    fileRemove (file, error, xhr) {
      const updateFiles = []
      this.files.forEach(elem => {
        if (elem.upload.uuid !== file.upload.uuid) {
          updateFiles.push(elem)
        }
      })
      this.files = updateFiles
    },
    fileError (file, message, xhr) {
      file.previewElement.style.setProperty('background-color', '#FF0000')
      this.$notify({
        group: 'lsg-notify',
        text: 'Файл не может быть загружен'
      })
    },
    fileProgress (file, progress, bytesSent) {
      file.previewElement.style.setProperty('--progress', progress + '%')
    },
    filesPicked (e) {
      this.files = e
    },
    closeModal () {
      this.uidEmailMessage = null
      this.showModal = false
    },
    openMessage (uid) {
      if (uid) {
        this.uidEmailMessage = uid + ''
        this.showModal = true
      }
    },
    doneLoadingMessages ({ data }) {
      if (data) this.isEmailLoading = false
    },
    updateStatus (status) {
      const id = this.$store.state.Lead._id
      this.$store.dispatch('updateLead', {
        context: this,
        data: { id, status }
      }).then(() => {
        this.$store.commit('incrementTradeRerenderKey')
        this.$store.commit('incrementCompanyRerenderKey')
        this.$notify({
          group: 'lsg-notify',
          text: 'Статус сделки изменен'
        })
      })
    },
    getStatusColor (status) {
      switch (status) {
        case 'Не обработано':
          return 'tag--blue'
        case 'Переговоры':
          return 'tag--purple'
        case 'Сбор документов':
          return 'tag--yellow'
        case 'Создание':
          return 'tag--dark-blue'
        case 'Рассмотрение':
          return 'tag--orange'
        case 'Одобрен':
          return 'tag--green'
        case 'Отказ служб':
          return 'tag--red'
      }
    },
    onScroll (event) {
      this.isLoading = this.onLoading
    },
    isOutdated (note) {
      return this.getStatus(note.complete_till_at) === 'Просроченные' && !note.is_completed
    },
    getStatus (date) {
      const target = _moment(date)
      if (_moment().range(_moment(Today).startOf('day'), _moment(Today).endOf('day')).contains(target)) {
        return 'На сегодня'
      } else if (_moment().range(_moment(Tomorrow).startOf('day'), _moment(Tomorrow).endOf('day')).contains(target)) {
        return 'На завтра'
      } else if (_moment().range(_moment(Today).startOf('day'), _moment(Week).endOf('week')).contains(target)) {
        return 'Эта неделя'
      } else if (_moment().range(_moment(NextWeek).startOf('week'), _moment(NextWeek).endOf('week')).contains(target)) {
        return 'След. неделя'
      } else if (_moment(NextWeek).endOf('week').format('x') < target.format('x')) {
        return 'После след. недели'
      } else {
        return 'Просроченные'
      }
    },
    calculatePosition (dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            }
          }]
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    addNoteIfPressCtrlAndEnter (e) {
      if (e.keyCode === 13 && e.ctrlKey) {
        e.preventDefault()
        if (this.noteMode === 'Комментарий') this.addNote()
        if (this.noteMode === 'Задача') this.addTask()
      }
    },
    addNoteIfClickOnButton (e) {
      e.preventDefault()
      if (this.noteMode === 'Комментарий') this.addNote()
      if (this.noteMode === 'Задача') this.addTask()
      if (this.noteMode === 'Email') this.sendMail()
    },
    async sendMail () {
      const HTTP = axios.create({
        baseURL: this.baseUrl,
        headers: {
          Authorization: `Bearer ${localStorage['apollo-token']}`
        }
      })
      if (!this.noteEdit) return

      const files = []

      for (let index = 0; index < this.files.length; index++) {
        files.push({
          path: await this.fileToBase64(this.files.item(index))
        })
      }

      const currentInput = {
        text: this.noteEdit,
        subject: this.subjectEdit,
        files: files
      }

      const url = `${BASE_URL}/api/mail/send?email=${this.$store.state.me.email}&recipient=${this.elementEmail}`
      this.$store.dispatch({ type: 'showOverlay', show: true, text: 'Подождите, отправка сообщения...' })
      await HTTP.post(url, currentInput)
        .then(res => {
          this.$router.go()
          this.$store.dispatch({ type: 'showOverlay', show: false, text: 'Подождите, отправка сообщения...' })
        })
        .catch(() => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Не удалось отправить сообщение'
          })
          this.$store.dispatch({ type: 'showOverlay', show: false, text: 'Подождите, отправка сообщения...' })
        })
    },
    fileToBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    addNote () {
      if (!this.noteEdit) return
      let currentInput = {
        element_type: this.elementType,
        element_id: this.elementId,
        note_type: 'Common',
        is_editable: true,
        text: this.noteEdit
      }
      if (this.editMode) currentInput.id = this.editNoteId
      this.$apollo.mutate({
        mutation: this.editMode ? UPDATE_NOTE : ADD_NOTE,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          this.$store.commit('incrementCompanyRerenderKey')
          if (data.addNote && data.addNote._id) {
            this.currentNotes.push(data.addNote)
            this.noteEdit = ''
            this.onResize()
          }
          if (data.updateNote && data.updateNote._id) {
            this.currentNotes.forEach(note => {
              if (note._id === data.updateNote._id) {
                note.text = data.updateNote.text
                note.createdAt = data.updateNote.createdAt
                this.clearEditMode()
              }
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
        this.$store.commit('incrementCompanyRerenderKey')
      })
    },
    pinNote (e, noteId) {
      e.preventDefault()
      this.$apollo.mutate({
        mutation: PIN_NOTE,
        variables: {
          id: noteId
        },
        update: (store, { data }) => {
          this.$store.commit('incrementCompanyRerenderKey')
          if (data.pinNote && data.pinNote._id) {
            this.onResize()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
        this.$store.commit('incrementCompanyRerenderKey')
      })
    },
    unpinNote (e, noteId) {
      e.preventDefault()
      this.$apollo.mutate({
        mutation: UNPIN_NOTE,
        variables: {
          id: noteId
        },
        update: (store, { data }) => {
          this.$store.commit('incrementCompanyRerenderKey')
          if (data.unpinNote && data.unpinNote._id) {
            this.onResize()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
        this.$store.commit('incrementCompanyRerenderKey')
      })
    },
    editNote (e, noteId) {
      e.preventDefault()
      this.editMode = true
      this.editNoteId = noteId
      this.lastNoteMode = this.noteMode
      this.noteMode = 'Комментарий'
      this.noteEdit = this.currentNotes.find(note => note._id === noteId).text
    },
    deleteNote (e, noteId) {
      e.preventDefault()
      if (noteId) {
        this.$apollo.mutate({
          mutation: DELETE_NOTE,
          variables: {
            id: noteId
          },
          update: (store, { data: { deleteNote } }) => {
            if (deleteNote._id) {
              this.currentNotes = this.currentNotes.filter(note => {
                if (note._id !== deleteNote._id) return note
              })
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    },
    addTask () {
      if (!this.editTaskManager._id) return
      let currentInput = {
        element_type: this.elementType,
        element_id: this.elementId,
        task_type: this.taskType.value,
        is_completed: false,
        complete_till_at: this.period,
        responsible_user_id: this.editTaskManager._id,
        text: this.noteEdit
      }
      if (this.editMode) currentInput.id = this.editNoteId
      this.$apollo.mutate({
        mutation: this.editMode ? UPDATE_TASK : ADD_TASK,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data.addTask && data.addTask._id) {
            this.currentNotes.push(data.addTask)
            this.noteEdit = ''
            this.onResize()
          }
          if (data.updateTask && data.updateTask._id) {
            this.currentNotes.forEach(note => {
              if (note._id === data.updateTask._id) {
                note.text = data.updateTask.text
                note.createdAt = data.updateTask.createdAt
                note.task_type = data.updateTask.task_type
                note.responsible_user = data.updateTask.responsible_user
                note.complete_till_at = data.updateTask.complete_till_at
                this.clearEditMode()
              }
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    editTask (e, taskId) {
      e.preventDefault()
      this.editMode = true
      this.editNoteId = taskId
      this.lastNoteMode = this.noteMode
      this.noteMode = 'Задача'
      this.currentNotes.forEach(note => {
        if (note._id === taskId) {
          this.noteEdit = note.text
          this.taskType = this.taskTypeSelect.find(type => type.value === note.task_type)
          this.period = new Date(note.complete_till_at)
          this.editTaskManager = note.responsible_user
        }
      })
    },
    deleteTask (e, taskId) {
      e.preventDefault()
      if (taskId) {
        this.$apollo.mutate({
          mutation: DELETE_TASK,
          variables: {
            id: taskId
          },
          update: (store, { data: { deleteTask } }) => {
            if (deleteTask._id) {
              this.currentNotes = this.currentNotes.filter(note => {
                if (note._id !== deleteTask._id) return note
              })
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    },
    completeTask (e, task) {
      e.preventDefault()
      const noteEdit = this.noteEdit
      let currentInput = {
        id: task._id,
        task_type: task.task_type,
        text: this.outdateText ? task.text + '\n' + this.outdateText : task.text,
        is_completed: true
      }
      this.$apollo.mutate({
        mutation: UPDATE_TASK,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data.updateTask && data.updateTask._id) {
            this.currentNotes.forEach(note => {
              if (note._id === data.updateTask._id) {
                note.is_completed = true
              }
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
      this.noteEdit = noteEdit
    },
    cancelEditMode (e) {
      e.preventDefault()
      this.clearEditMode()
    },
    clearEditMode () {
      this.noteMode = this.lastNoteMode
      this.noteEdit = ''
      this.editMode = false
      this.editNoteId = null
      this.taskType = {
        value: 'Call',
        name: 'Звонок'
      }
      this.period = new Date(Date.now())
      this.editTaskManager = {
        _id: this.$store.state.me._id,
        name: this.$store.state.name
      }
    },
    formatDate (datetime) {
      // return datetime ? moment(String(datetime)).format('DD.MM.YYYY HH:MM') : ''
      const date = new Date(datetime)
      return datetime ? new Date(date.setHours(date.getHours())).toLocaleString() : ''
    },
    getRouteOfElementType (elementType) {
      if (elementType === 'Contact') return 'contact'
      if (elementType === 'Company') return 'company'
      if (elementType === 'Lead') return 'trade'
    },
    onManagerChange (item) {
      if (item) {
        this.editTaskManager._id = item.value
        this.editTaskManager.name = item.label
      } else {
        this.editTaskManager._id = null
        this.editTaskManager.name = null
      }
    },
    getCurrentTaskType (taskType) {
      let currentType = this.taskTypeSelect.filter(type => {
        if (type.value === taskType) return type
      })

      return currentType.length && currentType[0].name ? currentType[0].name : 'Прочее'
    },
    clearEditTaskManager () {
      this.editTaskManager = {
        _id: this.$store.state.me._id,
        name: this.$store.state.me.name
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 768) {
        this.currentNotes.sort(function (a, b) {
          let dateA = new Date(a.createdAt)
          let dateB = new Date(b.createdAt)
          return dateB - dateA
        })
      } else {
        this.currentNotes.sort(function (a, b) {
          let dateA = new Date(a.createdAt)
          let dateB = new Date(b.createdAt)
          return dateA - dateB
        })
      }
    },
    scrollDown () {
      // this.$nextTick(() => {
      //   if (this.$refs.list && this.$refs.list.scrollHeight) this.$refs.list.$el.scrollTop = this.$refs.list.$el.scrollHeight
      // })
    }
  }
}
</script>

<style lang="stylus">

.crm__note
  &.hidden
    visibility hidden

  &__banned-employee
    margin-right 5px

.pinned__body
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  cursor pointer

.pinned__pagination
  display flex
  justify-content center
  align-items center
  border 1px solid #C9C9C9
  border-radius 20px
  // background-color $lightgray
  min-height 18px
  min-width 40px
  cursor pointer

.lsg-trade
  .main__content
    padding-bottom 30px !important

.crm
  align-items stretch !important
  max-height calc(100vh - 225px)

  +below(768px)
    display block !important
    max-height none
    width auto !important
    margin 0 !important

.crm__aside
  height auto !important

  +below(980px)
    max-width 350px !important

  +below(768px)
    max-width none !important
    width auto
    height auto !important
    margin 0 0 30px !important

.comment_fixed
  position fixed !important
  bottom 90px
  left 0
  width 100%

  &--hidden
    max-height 30px

.comment_block
  flex 1
  align-items initial
  justify-content flex-start
  max-width initial
  margin 0 -32px 0 -16px !important
  padding 0 !important
  max-height 90%
  max-width initial !important

  &::before
    content ''
    absolute left 16px top
    width calc(100% - 32px)
    height 30px
    background linear-gradient(top, $gray, alpha($gray, 0))
    z-index 5

  +below(768px)
    margin 0 15px 0 0 !important

.comment_block__top
  flex 1
  padding 15px
  overflow auto
  max-height calc(100% - 100px)
  box-sizing border-box
  position relative

  // +below(768px)
  //   max-height 500px

  // &--no-scroll
  //   max-height none !important
  //   margin-bottom 150px

  ul
    height auto
    padding 0
    margin 0
    overflow initial

.comment_block__bottom
  padding 15px 16px 0
  position relative

  .crm__notes__overdue
    position initial
    width auto
    margin 0 20px
    transform translateY(0)

    &__icon--disactive {
      path[stroke] {
        stroke gray
      }

      path[fill] {
        fill gray
      }
    }

  .crm__notes__form
    margin 0

.crm__note__text
  word-wrap break-word

.crm__notes__pinned
  position absolute
  top 0
  left 0
  max-width calc(100% - 35px)
  margin-left 15px
  z-index 6
  background-color #F7F9FC !important

.crm__notes__status
  border-radius: 5px !important;
  padding: 3px 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $c7ATRANS
  color: $c7A
  text-align: center;
  font-size: 0.9em;
  margin: 5px;
  cursor: pointer

.crm__notes__status__list
  display flex
  flex-wrap wrap
  flex-direction row

.crm__email
  display flex
  flex-direction column
  position relative
  // border 1px solid black
  width 80%
  max-width 720px

  background: $white;
  border: 1px solid rgba($darkgray, 0.3);
  box-sizing: border-box;
  box-shadow: 0 7px 30px rgba($black, 0.06);
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 8px;
  flex-shrink: 0;

  &.receive-mail:before
    content ''
    position absolute
    bottom -10px
    border: 20px solid transparent
    left 0
    border-left: 20px solid white

  &.send-mail:before
    content ''
    position absolute
    bottom -10px
    border: 20px solid transparent
    right 0
    border-right: 20px solid white

  &__header
    display flex
    flex-direction row

    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: $c7A;
    padding-bottom: 10px;
    border-bottom: 1px solid $pinky;
    margin-bottom: 10px;

    > *:not(:last-child)
      display flex
      margin-right 20px

  &__item
    display flex
    flex-direction row

    &:not(:last-child)
      margin-bottom 20px

    &--send
      justify-content flex-end

  &__list
    display flex
    flex-direction column
    width 100%
    height 100%

  &__footer {
    padding-top: 10px;
    border-top: 1px solid $pinky;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: $c7A;

    .btn {
      padding: 6px 8px;
      min-height: auto;
      margin: 0 16px;
      border-radius: 5px;
    }
  }

.upload-file
  opacity 0
  width 100%
  height 200px !important
  position absolute
  cursor pointer

.dropzone-container {
  min-width 60px
  min-height 60px
  margin 15px 0 0 10px
}

#dropzone {
  min-height 150px
  height 100%
}

.dropzone-icon {
  display flex
  justify-content center
  align-items center
  text-align center
  background: $white;
  border: 1px dashed $c4E555A;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left 10px
  max-width 350px
  width 60px
  height 100%
  cursor pointer

  &--full {
    width 100%
    margin-bottom 30px
  }

  i {
    width 22px
    height 22px
    display flex
    align-items center
    justify-content center
    margin-right 12px

    svg {
      width 100%
      height 100%
    }
  }
}

</style>
