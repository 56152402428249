<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="880.000000pt" height="1280.000000pt" viewBox="0 0 880.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#8b97a5" stroke="none">
  <path d="M4445 12794 c-570 -57 -1065 -179 -1608 -395 -624 -249 -1285 -625
    -1760 -1001 -424 -336 -805 -771 -958 -1092 -170 -357 -154 -711 40 -888 53
    -49 177 -118 211 -118 35 0 111 -62 165 -133 48 -64 63 -97 106 -223 65 -191
    98 -251 219 -404 116 -145 150 -206 150 -267 0 -55 -25 -104 -89 -171 -39 -41
    -71 -95 -135 -225 -47 -95 -99 -212 -116 -262 -152 -437 -141 -803 31 -1056
    45 -67 158 -180 244 -243 176 -132 434 -226 750 -273 172 -26 743 -26 960 0
    527 63 928 152 1425 316 204 68 499 179 667 252 29 13 54 21 56 18 2 -2 103
    -195 224 -429 121 -234 718 -1386 1328 -2560 1067 -2057 1111 -2139 1188
    -2235 188 -232 586 -684 947 -1074 124 -134 242 -262 263 -285 45 -49 46 -56
    -22 169 -29 94 -113 402 -187 685 -277 1054 -212 890 -770 1960 -256 492 -851
    1633 -1321 2534 -469 902 -850 1642 -846 1646 4 4 76 47 158 95 278 164 635
    412 904 628 537 432 934 893 1182 1371 83 161 123 260 154 385 82 329 44 636
    -108 869 -64 97 -126 163 -230 239 -295 216 -688 323 -1194 323 l-178 0 -47
    58 c-25 32 -106 123 -178 202 -325 357 -395 506 -369 775 25 258 -42 472 -183
    586 -105 84 -325 166 -554 205 -85 14 -433 27 -519 18z m2793 -4326 c-251
    -304 -776 -750 -1239 -1054 -168 -111 -436 -274 -451 -274 -24 0 5 22 114 85
    67 39 223 138 347 220 456 303 771 561 1101 903 91 94 167 171 168 171 2 1
    -16 -23 -40 -51z m-2498 -1727 c0 -18 -457 -197 -785 -306 -213 -71 -463 -141
    -472 -131 -2 2 11 7 29 11 228 49 891 281 1168 408 30 14 56 26 58 26 1 1 2
    -3 2 -8z m-1271 -445 c-6 -6 -138 -36 -152 -35 -7 1 20 9 58 19 70 18 102 24
    94 16z m-172 -42 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-50
    -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
  </g>
</svg>

</template>

<script>
export default {
  name: 'PinIcon'
}
</script>
