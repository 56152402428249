<template v-if="showModal">
  <ApolloQuery
    :query="require('@/graphql/queries/MailMessage.gql')"
    :variables="{ uidMessage }"
  >
    <template slot-scope="{ result: { error, data } }">
      <div class="modal modal--mails" style="align-items: flex-start">
        <div class="modal__back" @click="$emit('closeModal')"></div>
        <div class="modal__body">
          <div class="modal__attention">
            <div v-if="data" class="modal__attention__title">{{ data.MailMessage.from.filter(elem => elem.address === $store.state.me.email).length > 0 ? `Исходящее сообщение` : `Входящее сообщение` }}</div>
            <div class="modal__attention__container">
              <div class="ct__row" v-if="error">
                <div class="ct__col">
                  <div class="ct__input">
                    <label for="message">Ошибка:</label>
                    <input type="text" :value="error.message" id="message" disabled />
                  </div>
                </div>
              </div>
              <template v-if="data && data.MailMessage">
                <div class="modal__attention__subject modal__attention__title">{{ data.MailMessage.subject }}</div>
                <div class="modal__attention__flags">
                  <span class="tag" v-for="flag in data.MailMessage.flags" :key="flag">{{ transformFlags(flag) }}</span>
                </div>
                <div class="modal__attention__text">{{ data.MailMessage.text }}</div>
                <div class="modal__attention__attachments">
                  <a href="#" @click="downloadFile($store.state.me.email, data.MailMessage.uid, file.partId, file.filename)" class="modal__attention__attachments__item" v-for="file in data.MailMessage.attachments" :key="file.partId">
                    <span>{{ file.filename }}</span>
                    <span>{{ file.size }}</span>
                  </a>
                </div>
              </template>
            </div>
            <div class="modal__attention__btns">
              <button class="modal__attention__btn" @click="$emit('closeModal')">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import axios from 'axios'

export default {
  name: 'MailMessageModal',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    uidMessage: String
  },
  data () {
    return {
      show: true,
      message: null,
      baseUrl: process.env.VUE_APP_HTTP
    }
  },
  methods: {
    transformFlags (flag) {
      const flags = {
        '\\Answered': 'Отвечено',
        '\\Flagged': 'Флаг',
        '\\Deleted': 'Удалено',
        '\\Draft': 'Корзина',
        '\\Seen': 'Просмотрено'
      }
      return flags[flag] || ''
    },
    async downloadFile (email, uid, part, filename) {
      const HTTP = axios.create({
        baseURL: this.baseUrl,
        headers: {
          Authorization: `Bearer ${localStorage['apollo-token']}`
        }
      })

      const result = await HTTP.get(`api/mail/attachment`, {
        params: { email, uid, part },
        responseType: 'blob'
      })
        .then(response => {
          const blobFile = response.data
          const fileUrl = URL.createObjectURL(blobFile, { type: response.headers['content-type'] === 'application/octet-stream' ? 'application/pdf' : response.headers['content-type'] })
          let link = document.createElement('a')
          link.href = fileUrl
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
      return result
    }
  }
}
</script>

<style lang="stylus">
  .modal--mails
    > .modal__body
      white-space pre-wrap
      min-width 80%

      > .modal__attention
        width 100%

        >.modal__attention
          &__container
            overflow-y none

  .modal__attention__subject
    color $lightgray !important
    display flex
    justify-content flex-start

  .modal__attention__attachments
    display flex
    flex-direction column

  .modal__attention__attachments__item
    display flex
    flex-direction row
    justify-content space-between
    width 350px
    color $orange

</style>
