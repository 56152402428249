<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 6.6665C15 5.34042 14.4732 4.06865 13.5355 3.13097C12.5979 2.19329 11.3261 1.6665 10 1.6665C8.67392 1.6665 7.40215 2.19329 6.46447 3.13097C5.52678 4.06865 5 5.34042 5 6.6665C5 12.4998 2.5 14.1665 2.5 14.1665H17.5C17.5 14.1665 15 12.4998 15 6.6665Z" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M11.4414 17.5C11.2949 17.7526 11.0846 17.9622 10.8316 18.1079C10.5786 18.2537 10.2918 18.3304 9.99977 18.3304C9.70779 18.3304 9.42093 18.2537 9.16792 18.1079C8.9149 17.9622 8.70461 17.7526 8.55811 17.5" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
  </svg>
</template>

<script>
export default {
  name: 'RedBellIcon'
}
</script>
