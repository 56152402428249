<template>
  <div class="modal" v-if="candidateModal">
    <div class="modal__back" @click="$emit('switchModal')"></div>
    <div class="modal__body trade__modal--clamp">
      <form class="trade__modal" @submit.prevent="saveCandidate" autocomplete="off">
        <div class="trade__modal__header">
          <h1>Кандидат</h1>
          <button class="btn btn--gray" @click.prevent="$emit('switchModal')">Закрыть</button>
        </div>
        <div class="trade__modal__body">
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Статус:</label>
                <v-select v-model="candidate.status" :options="statuses" placeholder="Статус"/>
              </div>
            </div>
          </div>
          <div class="ct__row" v-if="candidate.status === 'Закрыт'">
            <div class="ct__col">
              <div class="ct__input">
                <label>Причина закрытия:</label>
                <input type="text" v-model="candidate.closed">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>ФИО:</label>
                <input type="text" v-model="candidate.name">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Телефон:</label>
                <div class="phones">
                  <div :key="index" class="phones__item" v-for="(phone, index) in candidate.phones">
                    <div class="phones__phone">
                      <input title="79990006699" type="text" v-model="phone.value" @input="thisShouldTriggerRecompute">
                    </div>
                    <div class="small-buttons">
                      <button type="button" @click="callPhone(phone.value)" class="btn btn--small" v-if="phone.value">
                        <PhoneIcon/>
                      </button>
                      <button type="button" @click="removePhone($event, index)" class="btn btn--small" v-if="index !== 0">
                        <MinusIco/>
                      </button>
                      <button type="button" @click="addPhone" class="btn btn--small">
                        <PlusIco/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Email</label>
                <input type="text" v-model="candidate.email">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Город</label>
                <input placeholder="Город" type="text" v-model="candidate.city">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Должность</label>
                <input placeholder="Должность" type="text" v-model="candidate.post">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Теги:</label>
                <TagsInput :tags="graphItem ? graphItem.tags.map(item => ({value: item.id, label: item.name})) : []" @input="onTagsInput"/>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Ответственный:</label>
                <v-select :value="{ label: candidate.responsible_user.name, value: candidate.responsible_user._id}" :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" placeholder="" @input="onUserChange"/>
              </div>
            </div>
          </div>
        </div>
        <div class="trade__modal__footer">
          <button class="btn" type="submit" v-if="!isChanged">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PlusIco from '@/components/svg/plus'
import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
import UPDATE_CANDIDATE from '@/graphql/mutations/UpdateCandidate.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import PhoneIcon from '../../../svg/phone'
import MinusIco from '../../../svg/minus'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CandidateDataModal',
  props: [ 'candidateModal', 'graphItem' ],
  components: { MinusIco, PhoneIcon, PlusIco, TagsInput },
  data: () => {
    return {
      candidate: {
        name: '',
        phones: [],
        email: '',
        city: '',
        post: '',
        tags: [],
        status: '',
        closed: '',
        responsible_user: {
          _id: null,
          name: null
        }
      },
      forceRecomputeCounter: 0,
      old: {},
      candidate_id: null,
      candidateActive: true,
      goToPerson: 'Перейти',
      statuses: ['Не обработано', 'Собеседование', 'Проверка СБ', 'Финалисты', 'Оформление', 'Закрыт']
    }
  },
  created () {
    this.candidate = { ...this.graphItem, phones: this.getCurrentInputPhones(this.graphItem.phone) }
  },
  computed: {
    isChanged () {
      return (this.candidate.name === '' || this.candidate.name === this.graphItem.name) &&
          JSON.stringify(this.candidate.phones) === JSON.stringify(this.graphItem.phone) &&
          (this.candidate.email === '' || this.candidate.email === this.graphItem.email) &&
          (this.candidate.city === '' || this.candidate.city === this.graphItem.city) &&
          (this.candidate.post === '' || this.candidate.post === this.graphItem.post) &&
          (this.candidate.status === '' || this.candidate.status === this.graphItem.status) &&
          (this.candidate.closed === '' || this.candidate.closed === this.graphItem.closed) &&
          (this.candidate.responsible_user._id === this.graphItem.responsible_user._id) &&
          JSON.stringify(this.candidate.tags) === JSON.stringify(this.graphItem.tags.map(item => item.id))
    }
  },
  // watch: {
  //   candidate (value) {
  //     if (
  //       value.name === '' &&
  //       (value.phones === [] || (value.phones[0] ?? value.phones[0].value === '')) &&
  //       value.email === '' &&
  //       value.city === '' &&
  //       value.post === ''
  //     ) {
  //       this.candidate_id = null
  //     }
  //   }
  // },
  methods: {
    getCurrentInputPhones (phones) {
      let currentPhones = []

      phones.forEach(phone => {
        if (phone.value) {
          currentPhones.push(phone)
        } else {
          currentPhones.push({ value: phone })
        }
      })

      return currentPhones
    },
    getCurrentPhone () {
      let currentPhones = []

      this.candidate.phones.forEach(phone => {
        if (phone.value) {
          currentPhones.push(phone.value)
        }
      })

      return currentPhones
    },
    thisShouldTriggerRecompute () {
      this.forceRecomputeCounter++
    },
    addPhone () {
      this.candidate.phones.push({ value: '' })
    },
    removePhone (e, index) {
      e.preventDefault()

      this.candidate.phones.splice(index, 1)
    },
    async saveCandidate (e) {
      e.preventDefault()
      let currentInput = {
        name: this.candidate.name,
        email: this.candidate.email,
        phone: this.candidate.phones.map(item => item.value),
        city: this.candidate.city,
        post: this.candidate.post,
        status: this.candidate.status,
        closed: this.candidate.closed,
        responsible_user_id: this.candidate.responsible_user._id,
        tags: this.candidate.tags,
        id: this.graphItem._id
      }

      await this.$apollo.mutate({
        mutation: UPDATE_CANDIDATE,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data) {
            this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    onUserChange (e) {
      if (e) {
        this.candidate.responsible_user._id = e.value
        this.candidate.responsible_user.name = e.label
      } else {
        this.candidate.responsible_user._id = null
        this.candidate.responsible_user.name = null
      }
    },
    onTagsInput (tags) {
      this.candidate.tags = tags.map(item => item.value)
    },
    onChangeInput ({ columnName, value }, index) {
      if (index === undefined) {
        this.candidate[columnName] = value
      } else {
        this.candidate.phones[index].value = value
      }
    },
    async callPhone (phone) {
      await this.$apollo.mutate({
        mutation: MAKE_CALL,
        variables: {
          phone
        },
        update: (_, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Звонок успешно совершён'
            })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  }
}
</script>

<style scoped>
  .modal {
    z-index: 99999;
  }
  .modal__attention__btn {
    margin: 0px 5px;
  }
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    pointer-events: none;
  }
</style>
