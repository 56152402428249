<template>
  <ApolloQuery
    :query="require('@/graphql/queries/Candidate.gql')"
    :variables="{ id }"
    class="lsg-page lsg-trade__page trade_page"
  >
    <template slot-scope="{ result: { loading, error, data } }">
      <div class="ct__row" v-if="error">
        <div class="ct__col">
          <div class="ct__input">
            <label for="message">Ошибка:</label>
            <input type="text" :value="error.message" id="message" disabled />
          </div>
        </div>
      </div>
      <div class="loading apollo" v-else-if="loading">Загрузка</div>
      <div class="lsg-trade" v-if="data && data.Candidate">
        <CandidateDataModal
          :graphItem = "data.Candidate"
          :candidateModal="candidateModal"
          v-on:switchModal="candidateModal = !candidateModal"
        />
        <div class="main__header">
          <div class="main__header__item left">
            <h1>{{ data.Candidate.name || title }}</h1>
          </div>
          <div class="main__header__item"></div>
          <div class="main__header__item right"></div>
        </div>
        <div class="main__content">
          <div class="crm">
            <aside class="crm__aside">
              <div class="workspace_left__header">
                <div class="workspace_left__row">
                  <div class="workspace_left__item">
                    <span
                      :class="getStatusColor(data.Candidate.status)"
                      class="tag"
                      >{{ data.Candidate.status || "Нет статуса" }}</span
                    >
                  </div>
                  <div class="workspace_left__item">
                    <ul class="workspace_left__tags">
                      <li
                        :key="index"
                        v-for="(tag, index) in data.Candidate.tags"
                      >
                        {{ tag.name }}
                      </li>
                    </ul>
                  </div>
                  <div class="workspace_left__created">
                    <span>{{ getDate(data.Candidate.createdAt) }}</span>
                  </div>
                </div>
                <div class="workspace_left__row" v-if="data.Candidate.post">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Должность</span>
                      <span>{{ data.Candidate.post }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="workspace_left__row"
                  v-for="phone in data.Candidate.phone"
                  :key="phone"
                >
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Телефон</span>
                      <span>{{ phone }}</span>
                    </div>
                  </div>
                  <div class="workspace_left__item">
                    <i @click="callPhone(phone)" style="cursor: pointer">
                      <PhoneOutIcon />
                    </i>
                  </div>
                </div>
                <div class="workspace_left__row" v-if="data.Candidate.email">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Email</span>
                      <span>{{ data.Candidate.email }}</span>
                    </div>
                  </div>
                </div>
                <div class="workspace_left__row" v-if="data.Candidate.city">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Город</span>
                      <span>{{ data.Candidate.city }}</span>
                    </div>
                  </div>
                </div>
                <div class="workspace_left__row mb5">
                  <div class="workspace_left__item">
                    <span class="workspace_left__manager">{{
                      data.Candidate.responsible_user.name
                    }}</span>
                  </div>
                  <div class="workspace_left__item">
                    <a
                      href="#"
                      class="settings"
                      @click.prevent="
                        candidateModal = true
                      "
                    >
                      <NewSettingsIcon />
                    </a>
                  </div>
                </div>
                <div class="workspace_left__row" v-if="data.Candidate.closed">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Причина закрытия</span>
                      <span>{{ data.Candidate.closed }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
            <LsgNotes
                v-if="data && data.Candidate"
                :isRightPage="true"
                elementType="Candidate"
                :elementId="data.Candidate._id"
                :notes="data.Candidate.notes || []"
                :tasks="data.Candidate.tasks || []"
            ></LsgNotes>
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import moment from 'moment'
import LsgNotes from '@/components/ui/LsgNotes'
import NewSettingsIcon from '../../components/svg/new/Settings'
import PhoneOutIcon from '../../components/svg/new/PhoneOutIcon'
import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import CandidateDataModal from '../../components/layouts/modals/components/CandidateDataModal.vue'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Candidate',

  props: ['id'],

  data: () => {
    return {
      title: 'ФИО кандидата',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Контакты',
          route: 'contacts'
        },
        {
          name: 'Редактирование контакта'
        }
      ],
      main_dropdown: false,
      leftSwitcher: false,
      candidateModal: false
    }
  },

  components: {
    LsgNotes,
    PhoneOutIcon,
    NewSettingsIcon,
    CandidateDataModal
  },

  methods: {
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    callPhone (phone) {
      this.$apollo
        .mutate({
          mutation: MAKE_CALL,
          variables: {
            phone
          },
          update: (_, { data }) => {
            if (data) {
              this.$notify({
                group: 'lsg-notify',
                text: 'Звонок успешно совершён'
              })
            }
          }
        })
        .catch((error) => {
          _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    getStatusColor (status) {
      switch (status) {
        case 'Не обработано':
          return 'tag--red'
        case 'Собеседование':
          return 'tag--orange'
        case 'Проверка СБ':
          return 'tag--blue'
        case 'Финалисты':
          return 'tag--dark-blue'
        case 'Оформление':
          return 'tag--green'
        case 'Закрыт':
          return 'tag--grey'
      }
    }
  }
}
</script>
